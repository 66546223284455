<template>
    <div class="code_content">

        <div class="code_panel">
            <div style="width: 100%;display: flex;align-items: center;justify-content: center;position: absolute;top:-90px">
                <img src="../../assets/images/logo/ptn/logo01.png" alt="" style="height: 70px">
            </div>
            <div style="width:30px;height:30px;display: flex;align-items: center;justify-content: center;position: absolute;top:0px;left: 0px">
                <i class="fa fa-close" style="font-size: 14px;cursor: pointer" @click="goback"></i>
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: nowrap;width: 100%">
                <span style="color: #d5d5d5"  @keyup.enter="checkCode">추천인코드</span>
                <div>
                    <input type="text" v-model="$store.state.userinsertagentcode">
                </div>
                <button @click="checkCode">확인</button>
            </div>

        </div>

    </div>
</template>

<script>
    import {loginRegisterMixin} from "../../common/mixin";
    import {checkAgentCode} from "../../network/userRequest";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";

    export default {
        name: "AgentCode",
        mixins: [loginRegisterMixin],
        data() {
            return {}
        },
        methods: {
            goback() {
                this.$store.state.userinsertagentcode = ''
                this.$router.go(-1)
            },
            checkCode() {
                if (this.$store.state.userinsertagentcode.trim() === '') {
                    this.$swal({
                        title: '추천인 코드를 입력하세요',
                        type: 'warning',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$store.commit(RECEIVE_SHOW_LOADING);
                checkAgentCode({code: this.$store.state.userinsertagentcode}).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                    this.$store.state.ischeckedagentcode = true;
                    if (res.data.success) {
                        this.$router.push("/register")
                    } else {
                        this.$swal({
                            title: '추천인 코드를 정확하게 입력하세요',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })

            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");

    .code_content {
        width: 100%;
        height: 100vh;
    }

    .code_panel {
        width: 375px;
        height: 90px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
        transform: translateX(-50%) translateY(-50%) scale(1);
        z-index: 10;
        border-radius: 10px;
        background: rgba(1, 136, 241, .8);
        border: 0px solid #01aff6;
        border-image: linear-gradient(to right, #01aff6, #01aff6) 1;
        box-shadow: 0 0 10px #01aff6;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .code_panel input {
        width: 100%;
        height: 28px;
        border-radius: 1px;
        background-color: #020202;
        text-align: center;
        color: #e8e8e8;
        margin: 0 5px;
    }

    .code_panel button {
        background-color: #c5dbf3;
        height: 28px;
        line-height: 28px;
        font-size: 13px;
        font-weight: bold;
        border-radius: 2px;
        width: 50px;
        color: #353539;
    }

</style>